<template>
  <div id="user-view">
    <v-row
      justify="end"
    >
      <v-avatar>
        <v-btn
          icon
          @click="$router.go(-1)"
        >
          <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
        </v-btn>
      </v-avatar>
    </v-row>
    <v-row
      justify="center"
      class="mt-0"
    >
      <v-col
        cols="12"
        md="4"
        lg="3"
      >
        <UserProfilePanel
          v-if="panelDetails"
          :panel-details="panelDetails"
        ></UserProfilePanel>
      </v-col>
      <v-col
        cols="12"
        md="8"
        lg="6"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(tab.title) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <OrderHistory
              v-if="orderDetails"
              :order-details="orderDetails"
            ></OrderHistory>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrderHistory from '@/components/UserDetails/OrderHistory.vue'
import UserProfilePanel from '@/components/UserDetails/UserProfilePanel.vue'
import { guestStatusMapping } from '@/composables/useGuestStatuses'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { mdiArrowLeft, mdiCardAccountDetails } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: {
    OrderHistory,
    UserProfilePanel,
  },
  setup() {
    const userTab = ref(null)
    const tabs = [{ icon: mdiCardAccountDetails, title: 'MyOrders' }]
    const orderDetails = ref()
    const panelDetails = ref()

    // guestStatusMapping.IN_PROGRESS

    const getOrders = () => {
      store.dispatch('getUserOrders').then(res => {
        orderDetails.value = res.data
        panelDetails.value = {
          activeOrders: orderDetails.value.filter(el => el.status === guestStatusMapping.IN_PROGRESS).length,
          notActiveOrder: orderDetails.value.filter(el => el.status !== guestStatusMapping.IN_PROGRESS).length,
        }
      })
    }
    getOrders()

    return {
      tabs,
      userTab,
      orderDetails,
      panelDetails,
      icons: {
        mdiArrowLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>

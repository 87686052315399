export default function useEventStatus() {
  const eventStatus = {
    ACTIVE: 1,
    NOT_ACTIVE: -1,
  }

  return {
    eventStatus,
  }
}

<template>
  <v-row>
    <!-- user profile preview -->
    <v-col cols="12">
      <v-card
        class="pt-8"
        flat
      >
        <v-card-title class="justify-center flex-column">
          <v-avatar
            size="110px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <span
              class="font-weight-medium text-h2"
            >{{ avatarText(userData.email) }}</span>
          </v-avatar>
        </v-card-title>
        <v-card-subtitle class="justify-center flex-column text-center">
          <div class="text-h6 font-weight-bold">
            {{ userData.fullName }}
          </div>
          <div class="mb-2 text-h8">
            {{ userData.email }}
          </div>
        </v-card-subtitle>

        <v-card-text class="d-flex justify-center flex-wrap mt-8 mb-4 pe-sm-0 flex-column align-content-center">
          <!-- Active order -->
          <div class="d-flex align-center me-8 mb-5">
            <v-avatar
              size="45"
              rounded
              color="primary"
              class="v-avatar-light-bg success--text me-3"
            >
              <v-icon
                color="success"
                size="30"
              >
                {{ icons.mdiTimerSand }}
              </v-icon>
            </v-avatar>
            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(panelDetails.activeOrders) }}
              </h3>
              <span>{{ $t('ActiveOrders') }}</span>
            </div>
          </div>
          <!-- Not active order -->
          <div class="d-flex align-center mb-6 me-4">
            <v-avatar
              size="45"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon
                color="primary"
                size="30"
              >
                {{ icons.mdiCheck }}
              </v-icon>
            </v-avatar>
            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(panelDetails.notActiveOrder) }}
              </h3>
              <span> {{ $t('FinishedOrders') }}</span>
            </div>
          </div>
        </v-card-text>
        <!-- User details footer -->
        <v-card-text class="pt-4 pb-5">
          <h2 class="text-xl font-weight-semibold mb-2">
            {{ $t('Details') }}
          </h2>
          <v-divider></v-divider>
          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2"> {{ $t('fullName') }}:</span>
              <span class="text--secondary">{{ userData.fullName }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('Email') }}:</span>
              <span class="text--secondary">{{ userData.email }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { avatarText, kFormatter } from '@core/utils/filter'
import { mdiCheck, mdiTimerSand } from '@mdi/js'

export default {
  props: ['panelDetails'],
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'))

    return {
      avatarText,
      userData,
      kFormatter,

      icons: {
        mdiTimerSand,
        mdiCheck,
      },
    }
  },
}
</script>

<template>
  <!-- loading page -->
  <div
    v-if="false"
    class="d-flex justify-center"
  >
    <v-progress-circular
      :size="50"
      indeterminate
      color="primary"
    >
    </v-progress-circular>
  </div>

  <v-container
    v-else
    class="pt-0 pa-0 ma-0"
  >
    <div
      v-if="orderDetails.length === 0"
      class="pt-2 d-flex flex-column align-center justify-center"
    >
      <div class="d-flex flex-column align-center justify-center">
        <p>
          {{ $t('NoData') }}
        </p>
      </div>
    </div>
    <!-- Card for each event -->
    <div v-if="orderDetails.length > 0">
      <v-row
        v-for="event in orderDetails"
        :key="event.eventId"
      >
        <v-col
          cols="12"
        >
          <v-card
            flat
            @click="getEventDetails(event.eventId), loading = true"
          >
            <v-card-text>
              <v-row>
                <!-- Event Details -->
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <h2>
                    {{ event.names[$i18n.locale] || event.names[event.defaultLang] }}
                  </h2>
                  <p class="mt-0 mb-3">
                    {{ $t('orderNumber') }}#{{ event.generatedId }}
                  </p>

                  <div class="d-flex align-center mt-0">
                    <h4 class="me-2">
                      <span class="me-1">
                        {{ $t('EventStatus') }}
                      </span>
                      <v-chip
                        label
                        color="primary"
                        small
                        class="v-chip-light-bg primary--text font-weight-semibold"
                      >
                        {{ event.status === eventStatus.ACTIVE ? $t('Active') : $t('NotActive') }}
                      </v-chip>
                    </h4>
                  </div>

                  <div class="d-flex align-center mt-2">
                    <h4 class="mb-1 me-2">
                      <span class="me-1">
                        {{ $t('TypeOfOrderPackage') }}
                      </span>

                      <v-chip
                        label
                        color="primary"
                        small
                        class="v-chip-light-bg primary--text font-weight-semibold mb-1"
                      >
                        {{ $t(event.packageName) }}
                      </v-chip>
                    </h4>
                  </div>
                  <div
                    class="mb-2 d-flex align-center"
                  >
                    <v-avatar
                      v-if="event.packageName.toLowerCase().includes('premium')"
                      size="28"
                      :class="`v-avatar-light-bg teal--text`"
                      class="me-1"
                    >
                      <v-icon
                        color="teal"
                        size="18"
                      >
                        {{ icons.mdiPhone }}
                      </v-icon>
                    </v-avatar>
                    <v-avatar
                      v-if="event.messageSystem === messagesType.WHATSAPP"
                      size="28"
                      :class="`v-avatar-light-bg success--text`"
                      class="me-1"
                    >
                      <v-icon
                        color="success"
                        size="18"
                      >
                        {{ icons.mdiWhatsapp }}
                      </v-icon>
                    </v-avatar>
                    <v-avatar
                      v-if="event.messageSystem === messagesType.SMS"
                      size="28"
                      :class="`v-avatar-light-bg indigo--text`"
                      class="me-1"
                    >
                      <v-icon
                        color="indigo"
                        size="18"
                      >
                        {{ icons.mdiMessageProcessingOutline }}
                      </v-icon>
                    </v-avatar>

                    <span class="me-1">
                      {{ $t('For') }}
                    </span>
                    <span class="me-1">
                      {{ event.combinedRows }}
                    </span>
                    <span>
                      {{ $t('Invitees') }}
                    </span>
                  </div>

                  <v-divider style="max-width: 50%;"></v-divider>
                  <div class="pt-1 me-1 font-weight-bold">
                    <span>
                      {{ $t('TotalPaid') }}
                    </span>
                    <span>
                      {{ event.totalPrice }}
                    </span>
                    <span>₪</span>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  sm="6"
                >
                  <!-- Details about starting RSVP's -->
                  <v-alert
                    text
                    :color="event.status === eventStatus.ACTIVE ? 'success' : 'primary'"
                    dark
                    class="pa-0"
                  >
                    <div
                      class="my-10 mx-3 justify-center align-center"
                      style="text-align-last: center;"
                    >
                      <v-icon
                        :color="event.status === eventStatus.ACTIVE ? 'success': 'secondary'"
                      >
                        {{ icons.mdiRun }}
                      </v-icon>
                      <span class="text--secondary">
                        <span
                          class="font-weight-semibold mb-1"
                        >
                          {{ $t('StartDateRsvp') }}
                        </span>
                        <p class="text-sm mb-0 font-weight-semibold">
                          {{ moment(event.startInvitationDate).format('DD/MM/YY') }}
                        </p>
                      </span>
                    </div>
                  </v-alert>

                  <!-- Upgrade package -->
                  <div class="text-center pt-3">
                    <v-btn
                      color="primary"
                      :disabled="event.status !== eventStatus.ACTIVE"
                      @click.stop="$router.push({name: 'pricing-page', params: { id: event.eventId }})"
                    >
                      {{ $t('UpgradePackage') }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import useEventStatus from '@/composables/useEventStatus'
import useMessagesType from '@/composables/useMessagesType'
import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { mdiMessageProcessingOutline, mdiPhone, mdiRun, mdiWhatsapp } from '@mdi/js'
import { ref } from '@vue/composition-api'
import moment from 'moment'

// messagesType

export default {
  props: ['orderDetails'],
  setup() {
    const eventsList = ref(null)
    const loading = ref(true)
    const { eventStatus } = useEventStatus()
    const { messagesType } = useMessagesType()

    const getEventDetails = eventId => {
      loading.value = true
      router.push({ name: 'home-page', params: { id: eventId } })
      loading.value = false
    }

    return {
      eventsList,
      moment,
      getEventDetails,
      loading,
      eventStatus,
      messagesType,

      icons: {
        mdiRun,
        mdiWhatsapp,
        mdiPhone,
        mdiMessageProcessingOutline,
      },
    }
  },
}
</script>
